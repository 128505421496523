@import '../../assets/css/breakpoints.scss';

.root {
    padding: 47px 20px 0 56px;
    @include mq('md') {
        padding: 47px 20px 0 86px;
    }
    .container {
        width: 100%;
        max-width: 946px;
        margin: 0 auto;
        // padding: 0 50px;
        .saleSection {
            display: grid;
            grid-template-columns: 1fr;
            gap: 69px;
            @include mq('lg') {
                grid-template-columns: 1fr 1fr;
            }
            .chartBox {
                background-color: var(--primary-color);
                border-radius: 12px;
                padding: 29px 33px;
                .markWrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 14px;
                    @include mq('sm') {
                        gap: 32px;
                    }
                    .mark {
                        width: 23px;
                        height: 23px;
                        border-radius: 4px;
                        margin-bottom: 12px;
                    }
                    .raised {
                        background-color: #7AF8FF;
                    }
                    .softCap {
                        background-color: #43ADB1;
                    }
                    .target {
                        background-color: #35858F;
                    }
                }
                .chart {
                    display: flex;
                    justify-content: center;
                    padding: 30px 0;
                }
            }
            .saleBox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .tokenWrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 11px;
                }
                .labelBox {
                    border: 1px solid var(--primary-color);
                    border-radius: 100px;
                    height: 42px;
                    padding: 0 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    input {
                        width: 100%;
                        height: 80%;
                        border: none;
                        outline: none;
                        color: var(--primary-color);
                    }
                }
                .selectBnb {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 4px;
                    @include mq('md') {
                        gap: 8px;
                    }
                    .bgPrimary {
                        background-color: var(--primary-color);
                        color: white;
                    }
                }
                .badge {
                    width: 100%;
                    height: 42px;
                    background-color: var(--primary-color);
                    border-radius: 100px;
                    // padding: 0 30px;
                    display: flex;
                    align-items: center;
                    color: white;
                    margin-bottom: 25px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .btn {
                    width: 100%;
                    height: 55px;
                    background-color: var(--primary-color);
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-family: 'Nunito', sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                    cursor: pointer;
                }
                .outlineBtn {
                    width: 100%;
                    height: 55px;
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: 'Nunito', sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                    border: 1px solid var(--primary-color);
                    cursor: pointer;
                }
            }
        }
        .timerSection {
            padding: 67px 0;
        }
        .link:hover {
            opacity: 0.6;
        }
    }
}